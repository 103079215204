$fonthead: 'Rubik', sans-serif;
$fontstack: 'Rubik', sans-serif;
$colordark: #353353;
$coloryellow: #ffd15c;
$colorpink: #ff4c60;
$colorblue: #6c6ce5;
$colordefault: #454360;

@mixin transition($second) {
	-webkit-transition: all $second ease-in-out;
	-moz-transition: all $second ease-in-out;
	transition: all $second ease-in-out;
}

@mixin grid($cols, $mgn) {
	float: left;
	margin-right: $mgn;
	margin-left: $mgn;
	width: calc((100% - (($cols - 1) * $mgn)) / $cols);
	&:nth-child(#{$cols}n) {
		margin-right: 0;
	}
}
